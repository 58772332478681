/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {Observable, catchError, tap, throwError} from 'rxjs';
import {
  EventType as AppEventType,
  ApplicationInsightsService,
} from 'src/app/shared/services/appInsights/app-insights.service';
import {CONSTANTS} from '../utils/constants/constants';

@Injectable()
export class GlobalErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _store: Store,
    private _appInsightsService: ApplicationInsightsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((data) => {
        this._appInsightsService.trackEvent(
          {name: CONSTANTS.EVENT_NAME.INTERCEPTOR},
          {
            event: AppEventType.ApiCall,
            action: CONSTANTS.EVENT_NAME.API_CALL,
            component: CONSTANTS.EVENT_NAME.INTERCEPTOR,
            body: request.body,
            headers: request.headers,
            params: request.params,
            url: request.url,
          }
        );
      }),
      catchError((error) => {
        const formattedMessage = this._appInsightsService.formatUserError(
          error.status
        );
        const errorObj = {
          params: request.params,
          body: request.body,
          headers: request.headers,
          error,
          formattedMessage,
        };
        this._appInsightsService.trackError({
          name: error.name,
          message: JSON.stringify(errorObj),
        });
        if (
          request.url.toLowerCase().includes('users') &&
          (error.status === 0 || // CORS error
            error.status === 401 ||
            error.status === 403 ||
            error.status === 500 ||
            error.status === 503)
        ) {
          this._router.navigate([CONSTANTS.ROUTES.GLOBAL_ERROR]);
        }
        return throwError(error);
      })
    );
  }
}
