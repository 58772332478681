import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CEButtonModule} from '@ce-lib/button';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AuthService} from '../core/auth/auth.service';
import {CONSTANTS} from '../core/utils/constants/constants';
import {UserState} from '../core/utils/state/user/user.state';
import {IUser} from '../shared/interfaces/user';
import {
  EventType as AppEventType,
  ApplicationInsightsService,
} from '../shared/services/appInsights/app-insights.service';
import {HelperService} from '../shared/services/helper/helper.service';

@Component({
  standalone: true,
  imports: [CEButtonModule],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(
    UserState.getCurrentUser
  );
  user!: IUser;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _helperService: HelperService,
    private _appInsightsService: ApplicationInsightsService
  ) {
    
    this.currentUser$.subscribe((res) => {
      this.user = {...res};
    });
  }
  ngOnInit(): void {
    this.login();
    if (
      this._authService.activeAccountExist() &&
      localStorage.getItem('hasError') !== 'true'
    ) {
      this._router.navigate(this._helperService.getDashboardRoute(this.user));
    }
  }

  login() {
    if (localStorage.getItem('hasError')) {
      localStorage.removeItem('hasError');
    }
    //'/main-dashboard' is protected by MsalGuard, this will trigger the msal login redirect
    this._authService.login();
    this._appInsightsService.trackEvent(
      {name: CONSTANTS.EVENT_NAME.LOG_IN},
      {
        event: AppEventType.ButtonClick,
        action: CONSTANTS.EVENT_NAME.LOG_IN,
        page: CONSTANTS.ROUTES.LOGIN,
        buttonName: 'Login',
      }
    );
  }
}
