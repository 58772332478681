import {Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {GlobalErrorComponent} from './global-error/global-error.component';
import {LoginComponent} from './login/login.component';

export const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', redirectTo: 'main-dashboard', pathMatch: 'full'},
  {
    path: 'main-dashboard',
    loadChildren: () =>
      import('./main-dashboard/dashboard.routes').then(
        (m) => m.dashboardRoutes
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.routes').then((m) => m.searchRoutes),
    canActivate: [MsalGuard],
  },
  {
    path: 'first-time',
    loadComponent: () =>
      import('./first-time-ux/first-time-ux.component').then(
        (m) => m.FirstTimeUxComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./contact-us/contact-us.component').then(
        (c) => c.ContactUsComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'mia',
    loadChildren: () => import('./mia/mia.module').then((m) => m.MiaModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'auth/login/aad/callback',
    loadComponent: () => import('./app.component').then((c) => c.AppComponent),
    canActivate: [MsalGuard],
  },
  {
    path: 'global-error',
    component: GlobalErrorComponent,
  },
  {
    path: '**',
    redirectTo: '/login-failed'
  },

];
