/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable()
export class ApimKeyInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bffeKey = environment.apiSubKey; // Sub key for UI to API
    if(request.url.includes('myinspection')) {
      request = request.clone({
        setHeaders: {
          'Ocp-Apim-Subscription-Key': environment.api.mia.apimSubKey,
          APIEnvironment: environment.apimEnvironment,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Ocp-Apim-Subscription-Key': bffeKey,
          APIEnvironment: environment.apimEnvironment,
        },
      });
    }

    request = request.clone({
      setHeaders: {
        "Permissions-Policy": "camera=*,geolocation=(),microphone=*,autoplay=*,fullscreen=(),picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*, browsing-topics=()",
        "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
        "X-Frame-Options": "SAMEORIGIN",
        "X-Content-Type-Options": "nosniff",
        "X-Xss-Protection": "1; mode=block",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:; frame-ancestors 'self'; object-src 'none';",
        "Referrer-Policy": "strict-origin"
      }
    });
    
    return next.handle(request);
  }
}
