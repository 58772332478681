import { CONSTANTS } from "src/app/core/utils/constants/constants";

export const environment = {
  production: true,
  apiServer: '#{apiServer}#',
  apiSubKey: '#{apiSubKey}#',
  apimEnvironment: '#{apimEnvironment}#',
  clientId: '#{clientId}#',
  authority: '#{authority}#',
  aadScopes: '#{aadScopes}#',
  redirectUri: '#{redirectUri}#',
  bingMapSubKey: '#{bingMapSubKey}#',
  bingMapUrl: '#{bingMapUrl}#',
  instrumentationKey: '#{instrumentationKey}#',
  clarity_id: '#{clarity_id}#',
  api: {
    mia: {
      url: 'https://webapi-dev.coned.com/coned-df-myinspection',
      apimSubKey: '7cfc63f40bf64beca72ccda083bcefe3',
      disableFod: true
    } 
  }
};
