import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../core/utils/constants/constants';
import {
    EventType as AppEventType,
    ApplicationInsightsService,
} from '../shared/services/appInsights/app-insights.service';

@Component({
  standalone: true,
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
  styleUrls: ['./global-error.component.scss'],
  imports: [],
})
export class GlobalErrorComponent {
  constructor(
    private _router: Router,
    private _appInsightsService: ApplicationInsightsService
  ) {}

  navigate() {
    localStorage.setItem('hasError', 'true');
    this._router.navigate(['login']);
  }

  createTicket() {
    this._appInsightsService.trackEvent(
      {name: CONSTANTS.EVENT_NAME.CREATE_TICKET},
      {
        event: AppEventType.ButtonClick,
        action: CONSTANTS.EVENT_NAME.RESET,
        page: CONSTANTS.ROUTES.GLOBAL_ERROR,
        buttonName: 'CREATE_TICKET',
        trackEvent: 'Clear old data',
      }
    );
    window.open(CONSTANTS.EXTERNAL_URLS.GLOBAL_ERROR_CREATE_TICKET, '_blank');
  }
}
