export const CONSTANTS = {
  ENDPOINTS: {
    MIA: {
      FACILITY: '/facilities',
      INSPECTIONS: '/inspections',
    },
    USERS: '/users',
    MECHANIC: '/mechanic',
    SUPERVISOR: '/supervisor',
    MEDIA: '/media',
    IMAGES: '/images',
    LOCATION_DATA: '/locationdata',
    QUESTIONS: '/questions',
    STRUCTURAL_DEFECTS: '/structural-defects',
    SEARCH: '/search',
    REPORTS: '/reports',
    FILTER_OPTIONS: '/filter-options',
  },
  USER_ROLES: {
    MECHANIC: 'Mechanic',
    SUPERVISOR: 'Supervisor',
  },
  PORTAL_ACCESS: {
    CUSTOMIZED: 'Customized',
    GENERAL: 'General',
  },
  JOB_STATUS: {
    IN_PROGRESS: 'In-Progress',
    SCHEDULED: 'Scheduled',
    PREVIOUS_SHIFT: 'Previous Shift',
    COMPLETED: 'Completed',
  },
  DEFECT_STATUSES: {
    DEFECT_IDENTIFIED_SUPERVISOR: 'Defect Identified_Supervisor',
    DEFECT_IDENTIFIED_MECHANIC: 'Defect Identified_Mechanic',
    DEFECT_CANCELLED: 'Defect Cancelled',
    DEFECT_IDENTIFIED_GENERAL: 'Defect Identified_General',
    DE_CIVIL_APPROVED: 'DE Civil Approved',
    DE_CIVIL_CLOSED: 'DE Civil Closed',
    DE_CIVIL_DOWNGRADED: 'DE Civil Downgraded',
    HISTORY: 'History'
  },
  STRUCTURE_IMAGE_STATUS: {
    IN_PROGRESS: 'In-Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  COLORS: {
    GREEN: '#00882B',
    BLUE: '#0078CF',
    ORANGE: '#FF8E2C',
  },
  ROUTES: {
    FIRST_TIME_SCREEN: 'first-time',
    MAIN_DASHBOARD: 'main-dashboard',
    GENERAL: 'general',
    LOGIN: 'login',
    CONTACT_US: 'contact-us',
    SUPERVISOR_JOBS: 'supervisor-jobs',
    MECHANIC_JOBS: 'mechanic-jobs',
    JOB_DETAILS: 'details',
    WORK_REQUESTS: 'work-requests',
    CREWS: 'crews',
    STRUCTURAL_DEFECTS: 'structural-defects',
    NEW_DEFECT: 'new',
    DEFECT_DETAILS: 'details',
    DEFECT_DETAILS_REVIEW: 'review',
    DEFECT_DETAILS_SUMMARY: 'summary',
    DEFECT_GALLERY: 'gallery',
    SEARCH: 'search',
    SEARCH_DEFECT: 'defect',
    GLOBAL_ERROR: 'global-error',
  },
  VALIDATORS: {
    REQUIRED: 'Required',
    MIN_LENGTH: 'minLength',
    MAX_LENGTH: 'maxLength',
  },
  ERROR_MESSAGES: {
    DEFECT_ALREADY_EXISTS:
      'This Asset already has a structural defect under review. Consult with your Supervisor if this appears to be a mistake.',
  },
  EXTERNAL_URLS: {
    GOOGLE_MAPS_DESTINATION: 'https://www.google.com/maps?daddr=',
    GLOBAL_ERROR_CREATE_TICKET: 'https://forms.office.com/r/reyK2n2Wkg',
    CONTACT_US_FORM:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Contact-Us.aspx',
    TUTORIALS:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Tutorials.aspx',
    TROUBLESHOOTING_GUIDE:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Troubleshooting-Guide.aspx',
    TERMS_AND_CONDITIONS: 'https://www.coned.com/en/terms-and-conditions',
  },
  TELEMETRY: {
    FILENAME: {
      PORTAL_GUARD: 'Portal Access Guard',
      APP_COMPONENT: 'App/Root Component',
    },
  },
  EVENT_NAME: {
    SESSION_EXPIRED: 'Session Expired',
    PORTAL_GUARD: 'Portal Access Guard',
    CREATE_TICKET: 'Create Defect',
    EDIT_TICKET: 'Edit/View Defect',
    VIEW_TICKET: 'View Defect',
    SAVE_TICKET: 'Save Defect',
    SUBMIT_TICKET: 'Submit Defect',
    CANCEL_TICKET: 'Cancel Defect',
    // DELETE_TICKET: 'Delete Ticket',
    // ASSIGN_MECHANIC: 'Assign Mechanic',
    // SELECT_WORK_TYPE: 'Select Work Type',
    // DOWNLOAD_PDF: 'Download PDF',
    // VIEW_PDF: 'View PDF',
    // LOCATION_CHANGE:'Location Change',
    // WR_NUMBER: 'Work Request Number',
    // MANUALLY_ENTER_STRUCTURE: 'Manually Enter Structure',
    // VALIDATE_STRUCTURE: 'Validate Structure',
    // INSPECTION_INFORMATION: 'Inspection Information',
    GET_STARTED: 'Get Started',
    SEARCH: 'Search',
    ASSET: 'Asset',
    VALIDATE: 'Validate',
    NEXT: 'Next',
    RESET: 'Reset',
    CHANGE: 'Selection Change',
    PREVIOUS: 'Previous',
    ON_LOCATION: 'On Location',
    // SKIP_WR: 'Skip WR',
    NAVIGATE: 'Navigate',
    API_FAILURE: 'API Failure',
    API_CALL: 'API Call',
    FETCH_DATA: 'Fetch data from backend',
    SUBMIT: 'Submit',
    YES: 'Yes',
    SHOW_DIRECT_REPORTS: 'Show direct reports',
    // SHOW_IN_PROGRESS_CONFIRMATION: 'In-Progress confirmation',
    APPLY_FILTERS: 'Apply filters',
    // DOWNLOAD_IMAGES: 'Download Images',
    DELETE_IMAGE: 'Delete Image',
    SAVE_IMAGE: 'Save Image',
    UPDATE_IMAGE: 'Update Image',
    IMAGE_ACTION: 'Image Actions',
    VIEW_IMAGE: 'View Image',
    IMAGE_SELECTED: 'Image Selected',
    IMAGE_UPLOAD: 'Image Upload',
    CANCEL_UPLOAD: 'Cancel Upload',
    OPEN_CAMERA: 'Open Camera',
    CLOSE_CAMERA: 'Close Camera',
    CAPTURE_IMAGE: 'Capture Image',
    DISPLAY_MEDIA: 'Display Media',
    // SAVE_FOR_LATER: 'Safe for later',
    VIEW_SUMMARY: 'View Summary',
    SHOW_CLOSE_MODAL: 'Show/Close modal',
    LOG_IN: 'Log in',
    LOG_OUT: 'Log out',
    INSTALL_PWA: 'Install PWA',
    OPT_OUT_INSTALL_PWA: 'Install PWA Opt Out',
    AUTHENTICATION_SUCCESS: 'User Authentication Successful',
    // ADD_JOINT: 'Add Joint',
    // EDIT_JOINT: 'Edit Joint',
    // DELETE_JOINT: 'Delete Joint',
    // SAVE_JOINT: 'Save Edited Joint',
    // CLEAR_JOIN: 'Clear Joint Form',
    // VERIFY: 'Verify',
    // REVISION_BY_MECHANIC: 'Revision by Mechanic',
    ALERT: 'Alert',
    // WR_NOT_FOUND: 'Work Request Number Invalid',
    // REFRESH_DASHBOARD: 'Refresh Dashboard',
    BEGIN_TICKET: 'Begin Ticket',
    GOOGLE_MAPS: 'Google Maps',
    // RECALL: 'Recall',
    // REVISION_COMPLETE: 'Revision Complete',
    // DELETE_VIDEO: 'Delete Video',
    // GENERATE_PDF_FAILURE: ' Generate PDF Failure',
    SERVICE_WORKER_UPDATE: 'Service Worker Update',
    EDIT_IMAGE: 'Edit Image',
    EXCEPTION: 'Exception',
    UPLOAD_FILE: 'Upload File',
    INTERCEPTOR: 'Interceptor',
    // UPLOAD_VIDEO: 'Upload Video',
    SUBMIT_INSPECTION: 'Submit Inspection',
  },
  QUESTION_TYPES: {
    DATEPICKER: 'Datepicker',
  },
};
